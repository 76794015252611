/*
 * @Description: 应用入口
 * @Author: Feng Yinchao
 * @Date: 2023-03-08 14:27:11
 */

import { generateWaterMark } from './core/generateWaterMark'
import { Observer } from './core/observer'

// 默认的水印配置
const defaultConfig = {
  target: document.body,
  text: 'Zonst WaterMark',
  color: 'rgba(0, 0, 0, 0.07)',
  fontSize: 24,
  zIndex: '1000',
  secret: false,
  secretColor: 'red',
  intensity: 0,
  angle: -30,
  onchange: (mutation: MutationRecord) => console.log('watermark.onchange happened:', mutation),
  onerror: (msg?: string) => console.log('watermark.onerror happened:', msg),
  success: (msg?: string) => console.log('watermark.success happened:', msg),
}

export type UserWaterMarkConfig = Partial<typeof defaultConfig>
export type WaterMarkConfig = UserWaterMarkConfig & { image?: string; imageWidth: number; imageHeight: number }
class WaterMark {
  static initialized = false
  static init: (userConfig: UserWaterMarkConfig) => { remove: Function } = userConfig => {
    // 防止多次初始化
    if (WaterMark.initialized) {
      return { remove: () => {} }
    }
    const config = { ...defaultConfig, ...userConfig }
    try {
      let observeHidden: Observer | null = null
      if (config.secret) {
        // 添加暗水印
        observeHidden = generateWaterMark(config)
        // 监控暗水印变化
        observeHidden.start()
      }
      // 添加明水印
      const observeVisible = generateWaterMark({ ...config, secret: false })
      // 监控明水印变化
      observeVisible.start()
      WaterMark.initialized = true
      return {
        remove: () => {
          observeVisible.stop()
          observeHidden?.stop()
        },
      }
    } catch (err) {
      config.onerror?.(`${err}`)
      return { remove: () => {} }
    }
  }
}

export default WaterMark
