/*
 * @Description: 根据水印的配置生成水印元素，并添加到target中
 * @Author: Feng Yinchao
 * @Date: 2023-03-09 09:41:47
 */

import { WaterMarkConfig } from '@/watermark'

export const createAndMountWatermarkDiv: (config: WaterMarkConfig, shouldHidden?: boolean) => HTMLDivElement = (
  config,
  shouldHidden,
) => {
  const watermark = document.createElement('div')
  const { target, image, imageWidth, imageHeight, zIndex } = config

  const watermarkStyle: { [key: string]: string } = {
    background: `url(${image}) repeat ${imageWidth}px ${imageHeight}px`,
    position: target === document.body ? 'fixed' : 'absolute',
    top: '0',
    bottom: '0',
    left: '0',
    right: '0',
    'pointer-events': 'none',
    'z-index': zIndex!,
    width: '100%',
    height: '100%',
    opacity: '1',
    transform: 'none',
  }

  if (shouldHidden) {
    watermarkStyle.opacity = '0.01'
    watermarkStyle.top = '50px'
  }

  let style = ''
  for (const key in watermarkStyle) {
    style += `${key}: ${watermarkStyle[key]} !important; `
  }

  watermark.setAttribute('style', style)
  config.target!.style.position = 'relative'
  config.target?.appendChild(watermark)

  return watermark
}
