/*
 * @Description: 向页面中添加文字水印 并 监听水印节点是否被删除
 * @Author: Feng Yinchao
 * @Date: 2023-03-09 09:40:02
 */
import { UserWaterMarkConfig, WaterMarkConfig } from '@/watermark'
import { createAndMountWatermarkDiv } from '../utils'
import { generateImageUseCanvas } from './generateImageUseCanvas'
import { Observer } from './observer'

/**
 * @description 向页面中添加文字水印
 * @param config 水印配置
 * @return 监视器observe
 */
export const generateWaterMark: (config: UserWaterMarkConfig) => Observer = config => {
  const configWithImageURL = generateImageUseCanvas(config as WaterMarkConfig)
  const watermarkDiv = createAndMountWatermarkDiv(configWithImageURL, config.secret)
  const { onchange, success } = config
  const observe = new Observer(watermarkDiv, onchange!)
  success!()
  return observe
}
