/*
 * @Description: 监听水印节点是否被删除
 * @Author: Feng Yinchao
 * @Date: 2023-03-09 08:55:05
 */
export class Observer {
  watermarkParent: HTMLElement
  watermarkClone: HTMLElement
  observer!: MutationObserver

  constructor(public watermark: HTMLElement, public onchange: Function) {
    // 获取watermark的父元素：监视的对象
    this.watermarkParent = this.watermark.parentElement as HTMLElement
    // 克隆一个watermark：当watermark被删除时添加watermarkClone
    this.watermarkClone = watermark.cloneNode(true) as HTMLElement
  }

  start() {
    const config = { characterData: true, attributes: true, childList: true, subtree: true }

    this.observer = new MutationObserver(this.callback)
    if (!this.observer) {
      throw new Error(`Not exist: new MutationObserver()`)
    }
    this.observer.observe(this.watermarkParent, config)
  }

  stop() {
    this.observer.disconnect()
    this.watermark.remove()
  }

  private callback = (mutationsList: MutationRecord[]) => {
    let needRestart = false
    for (const mutation of mutationsList) {
      if (mutation.type === 'childList') {
        for (let i = 0; i < mutation.removedNodes.length; i++) {
          if (mutation.removedNodes[i] === this.watermark) {
            needRestart = true
            break
          }
        }
      } else if (mutation.target === this.watermark) {
        needRestart = true
      }
      if (needRestart) {
        this.onchange(mutation)
        this.mountWatermark()
        this.start()
        break
      }
    }
  }

  // 重新添加水印dom
  mountWatermark() {
    this.watermark?.remove()
    const newWatermark = this.watermarkClone.cloneNode(true) as HTMLElement
    this.watermarkParent.appendChild(newWatermark)
    this.watermark = newWatermark
    this.observer.disconnect()
    this.start()
  }
}
