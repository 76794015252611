/*
 * @Description: 使用 canvas 绘制水印文字并转化为一张图片
 * @Author: Feng Yinchao
 * @Date: 2023-03-09 10:28:33
 */
import { getTextSize } from '@/utils'
import { WaterMarkConfig } from '@/watermark'

export function generateImageUseCanvas(config: WaterMarkConfig): WaterMarkConfig {
  const canvas = document.createElement('canvas')
  const { width, height } = getTextSize(config.text!, config.fontSize!)
  const ctx = canvas.getContext('2d')

  if (!ctx) {
    throw new Error('您的浏览器不支持 canvas')
  }

  const dpr = 1
  canvas.width = (width + width * config.intensity!) * dpr
  // 确保旋转后的图片不会被裁切而导致显示不全
  canvas.height =
    (height + height * config.intensity! + width * Math.sin((Math.abs(config.angle!) / 180) * Math.PI)) * dpr

  ctx.font = `${config.fontSize}px Microsoft YaHei`
  ctx.fillStyle = config.secret ? config.secretColor! : config.color!
  ctx.textAlign = 'center'
  ctx.textBaseline = 'middle'
  ctx.translate(canvas.width / 2, canvas.height / 2)
  ctx.rotate((Math.PI / 180) * config.angle!)
  ctx.scale(dpr, dpr)
  ctx.fillText(config.text!, 0, 0)

  config.imageWidth = canvas.width / dpr
  config.imageHeight = canvas.height / dpr
  config.image = canvas.toDataURL()
  return config
}
